import { useInviteRecords } from '@/hooks/useInviteRecords';
import useLang from '@/hooks/useLang';
// import { getTabBg } from '@/styles';
import { getTabBg } from '@/components/home/rank-list/tab';
import { gaSend } from '@/utils/ga';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NewPlayers } from './NewPlayers';
import { OpeningData } from './OpeningData';
import { TabTotalData } from './TotalData';

const Tabs = [
  ['Historical', 'Total Data'],
  ['Treasure Chest', 'Opening Data'],
  ['Data For Inviting', 'New Players'],
];

interface Props {}

export const StatisticsTabs = (props: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    records,
    getFisrtPage,
    isTimeout,
    hasMore,
    loadMore,
    loading,
    refetch,
  } = useInviteRecords();
  const { __ } = useLang();

  useEffect(() => {
    if (activeTab === 1) {
      getFisrtPage();
    }
  }, [activeTab]);

  return (
    <Box px={2}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            textAlign: 'center',
          }}
        >
          {Tabs.map((tab, index) => {
            const isActive = activeTab === index;
            return (
              <Box
                sx={{
                  flex: 1,
                  minWidth: 0,
                  maxWidth: '33.3333%',
                  px: 1,
                  height: '48px',
                  pb: 1.5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  borderRadius: '8px 8px 0 0',
                  cursor: 'pointer',
                  background: isActive ? getTabBg().background : '',
                }}
                key={index}
                onClick={() => {
                  if (index === 0) {
                    gaSend(
                      '邀请模块',
                      '邀请总数据历史记录 - 点击',
                      '邀请统计页：totais de dados',
                    );
                  } else if (index === 1) {
                    gaSend(
                      '邀请模块',
                      '邀请详情 - 点击',
                      '邀请统计页：Detalhes de convite',
                    );
                  } else if (index === 2) {
                    gaSend(
                      '邀请模块',
                      '邀请详情 - 新玩家邀请数据',
                      '邀请统计页：Dados de convites novos jogadores',
                    );
                  }

                  setActiveTab(index);
                }}
              >
                {tab.map((item) => {
                  return (
                    <Typography
                      key={item}
                      variant="body2"
                      color={isActive ? 'text.primary' : 'text.secondary'}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      lineHeight={1}
                      // lineHeight={isActive ? 1.25 : 1}
                    >
                      {__(item)}
                    </Typography>
                  );
                })}
              </Box>
            );
          })}
        </Box>
        {activeTab === 0 ? <TabTotalData /> : null}
        {activeTab === 1 ? (
          <OpeningData
            records={records}
            isTimeout={isTimeout}
            refetch={refetch}
            hasMore={hasMore}
            loadMore={loadMore}
            loading={loading}
          />
        ) : null}
        {activeTab === 2 ? <NewPlayers /> : null}
      </Box>
    </Box>
  );
};
