import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { showIOSDownLoadState } from '@/stores/auth';
import { globalMaxWidth } from '@/theme';
import { Box, Dialog, SwipeableDrawer } from '@mui/material';
import { useRecoilState } from 'recoil';
import DrawerHeder from '../../pages/Me/components/DrawerHeder';
import IOSDownLoadConten from './IOSDownLoadConten';

interface Props {
  lastSign?: number;
}

const IOSDownLoadDrawer = (props: Props) => {
  const { __ } = useLang();
  const [showIOSDownLoad, setShowIOSDownLoad] =
    useRecoilState(showIOSDownLoadState);

  const isMobile = useResponsive('down', 'md');

  const _onClose = () => {
    setShowIOSDownLoad(false);
  };

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={showIOSDownLoad}
        onClose={() => {
          setShowIOSDownLoad(false);
        }}
        onOpen={() => setShowIOSDownLoad(true)}
        anchor={isMobile ? 'bottom' : 'right'}
        disableSwipeToOpen
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: globalMaxWidth,
            left: {
              xs: 0,
              sm: `calc(50% - ${globalMaxWidth / 2}px)`,
            },
          },
        }}
        sx={{ zIndex: 9999 }}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
      >
        <Box bgcolor="card.main" pb={2}>
          <DrawerHeder title={__('Como instalar?')} onClose={_onClose} />
          <IOSDownLoadConten />
        </Box>
      </SwipeableDrawer>
    );
  }

  return (
    <Dialog
      open={showIOSDownLoad}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
        },
      }}
      onClose={() => {
        setShowIOSDownLoad(false);
      }}
      aria-labelledby="sign-in"
    >
      <Box bgcolor="card.main" pb={2}>
        <DrawerHeder title={__('Como instalar?')} onClose={_onClose} />
        <IOSDownLoadConten />
      </Box>
    </Dialog>
  );
};

export default IOSDownLoadDrawer;
