import gameBg from '@/assets/images/gameBg.png';
import useLang from '@/hooks/useLang';
import { useWidth } from '@/hooks/useWidth';
import { IGameCategoryImg, IGameItem } from '@/types/config';
import { hideScrollbarX } from '@/utils/cssStyles';
import { gaSend } from '@/utils/ga';
import { Box, Button, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { GameCard } from './GameList';

interface Props {
  title: string;
  desc?: string;
  icon?: IGameCategoryImg;
  bgImg?: IGameCategoryImg;
  list: IGameItem[];
  onClickMore?: () => void;
}

export const FlishGameList = (props: Props) => {
  const { __ } = useLang();
  const width = useWidth();

  const onClickMore = () => {
    gaSend(
      '首页模块',
      '更多捕鱼游戏 - 点击',
      '首页：Flshing Games 【Ver Todos】',
    );
    props.onClickMore?.();
  };

  return (
    <Box pt={3}>
      <Box
        sx={{
          backgroundImage: `url(${props?.bgImg?.transverse || gameBg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          mb: 2.5,
          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            // pt: '24px',
            position: 'relative',
            // overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              top: '-24px',
              width: 'auto',
              height: 'calc(100% + 24px)',
            }}
          >
            <img
              src={props?.icon?.square}
              alt=""
              style={{ width: 'auto', height: '100%' }}
            />
          </Box>
          <Box
            sx={{
              // backgroundImage: `url(${props?.bgImg?.transverse || gameBg})`,
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px',
              p: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '700',
                fontSize: 20,
                lineHeight: 1,
                fontStyle: 'italic',
              }}
            >
              {props?.title}
            </Typography>
            <Button
              sx={{
                color: 'text.secondary',
                background:
                  'linear-gradient(0deg, rgba(28, 32, 38, 0.75)0%, rgba(28, 32, 38, 0.75)100%), rgba(255, 255, 255, 0.15)',
                borderRadius: '4px',
                height: '24px',
                fontSize: 12,
                lineHeight: 1,
                px: 1,
                minWidth: 0,
                mt: '8px',
                display: 'inline-block',
              }}
              variant="text"
              size="small"
              onClick={onClickMore}
            >
              {__('View All')}
            </Button>
          </Box>
        </Box>
        <Box
          p={'6px'}
          px={0}
          sx={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.50)0%, rgba(0, 0, 0, 0.50)100%), rgba(255, 255, 255, 0.15)',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            backdropFilter: 'blur(6px)',
          }}
        >
          <Box
            sx={{
              ...hideScrollbarX,
              display: 'flex',
            }}
          >
            {props.list.map((item, index) => {
              return (
                <Box
                  key={item.id + index}
                  mr="6px"
                  ml={index === 0 ? '6px' : 0}
                >
                  <GameCard
                    item={item}
                    width={width}
                    height={(96 * width) / 72}
                    imgHeight="100%"
                    gaEvent={{
                      category: '首页模块',
                      action: '捕鱼游戏进入 - 点击',
                      label: '首页：Flshing Games',
                    }}
                  />
                </Box>
              );
            })}
          </Box>
          {/* <Grid container columns={24} spacing={'6px'} sx={{ px: '8px' }}>
            {showList.map((item, index) => {
              return (
                <Grid item key={item.id} xs={8}>
                  <GameCard item={item} />
                </Grid>
              );
            })}
          </Grid> */}
        </Box>
      </Box>
    </Box>
  );
};

// export const FlishGameListWeb = (props: Props) => {
//   const { __ } = useLang();
//   const showList = props.list.slice(0, 8);

//   return (
//     <Box sx={{ position: 'relative', mt: '16px', pt: '24px' }}>
//       {props?.icon?.square ? (
//         <Box
//           sx={{
//             position: 'absolute',
//             top: 0,
//             left: '50%',
//             transform: 'translateX(-50%)',
//             display: 'block',
//           }}
//         >
//           <img
//             src={props?.icon?.square}
//             alt=""
//             style={{ width: '48px', height: '48px' }}
//           />
//         </Box>
//       ) : null}
//       <Box
//         sx={{
//           bgcolor: 'background.paper',
//           pb: '12px',
//           borderRadius: '8px',
//         }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             backgroundImage: `url(${props?.bgImg?.vertocal})`,
//             backgroundSize: 'cover',
//             backgroundRepeat: 'no-repeat',
//             height: '98px',
//             mb: '12px',
//           }}
//         >
//           <Center sx={{ mt: '32px' }} flexDirection="column">
//             <Typography
//               variant="subtitle1"
//               // className="br-game-font"
//               className="home-game-list-font"
//               sx={{
//                 fontWeight: '700',
//                 px: 1,
//                 overflow: 'hidden',
//                 width: '100%',
//                 textOverflow: 'ellipsis',
//                 textAlign: 'center',
//                 textShadow: '10px 3px 6px rgba(0, 0, 0, 0.16)',
//               }}
//             >
//               {props?.title}
//             </Typography>
//             <Button
//               sx={{
//                 height: '24px',
//                 color: 'text.secondary',
//                 bgcolor: 'background.neutral',
//                 borderRadius: '12px',
//                 fontSize: 12,
//                 fontWeight: 400,
//                 minWidth: 0,
//                 px: 1,
//                 mt: '8px',
//               }}
//               variant="text"
//               size="small"
//               onClick={props.onClickMore}
//             >
//               {__('View All')}
//             </Button>
//           </Center>
//         </Box>
//         <Grid
//           container
//           gridRow={1}
//           columns={24}
//           spacing={{ xs: 1.5, sm: 2 }}
//           sx={{ px: '12px' }}
//         >
//           {showList.map((item, index) => {
//             return (
//               <Grid item key={item.id} md={3}>
//                 <GameCard item={item} />
//               </Grid>
//             );
//           })}
//         </Grid>
//       </Box>
//     </Box>
//   );
// };
