import IC_QUESTION from '@/assets/icons/icon_question.png';
import card_15 from '@/assets/images/card_15.png';
import card_15_dis from '@/assets/images/card_15_dis.png';
import card_30 from '@/assets/images/card_30.png';
import card_30_dis from '@/assets/images/card_30_dis.png';
import card_7 from '@/assets/images/card_7.png';
import card_7_dis from '@/assets/images/card_7_dis.png';
import card_Bottom_15 from '@/assets/images/card_Bottom_15.png';
import card_Bottom_30 from '@/assets/images/card_Bottom_30.png';
import card_Bottom_7 from '@/assets/images/card_Bottom_7.png';
import card_Bottom_limit from '@/assets/images/card_Bottom_limit.png';
import {
  default as card_limit,
  default as card_limit_dis,
} from '@/assets/images/card_limit.png';
import cardBox_15 from '@/assets/images/cardBox_15.png';
import cardBox_30 from '@/assets/images/cardBox_30.png';
import cardBox_7 from '@/assets/images/cardBox_7.png';
import cardBox_disable from '@/assets/images/cardBox_disable.png';
import cardBox_limit from '@/assets/images/cardBox_limit.png';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { CommonLoading } from '@/components/SuspenseLoading';
import WeekCardDepositDrawer from '@/components/wallet/WeekCardDepositDrawer';
import WeekCardFailDrawer from '@/components/wallet/WeekCardFailDrawer';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { weekCardDto } from '@/hooks/useWeekCardBonus';
import services from '@/services';
import { isOpenDepositState, showMoneyDialogState } from '@/stores/auth';
import { weekCardActivityState } from '@/stores/config';
import { globalMaxWidth } from '@/theme';
import { ActivityType, LanguageType } from '@/types';
import { IDeposit } from '@/types/config';
import { gaSend } from '@/utils/ga';
import {
  Box,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import CardRuleDrawer from '../components/CardRuleDrawer';
import PackageResDialog from '../components/PackageResDialog';
interface Props {
  list: weekCardDto[];
  loading: boolean;
  deposit: IDeposit | null;
  onBuyCb: (info: any) => void;
}

const CardBottomBtn = ({
  item,
  onClick,
}: {
  item: any;
  onClick: (data: any) => Promise<void>;
}) => {
  const { __ } = useLang();
  const [loading, setLoading] = useState<boolean>(false);
  let activityRule = item?.activityRulesList[0] || {};
  let bg1 =
    item.showLevel === 4
      ? 'linear-gradient(90deg, #D53369 0%, #F28561 100%)'
      : item.showLevel === 1
      ? 'linear-gradient(90deg, #ADA7CB 0%, #7973A3 100%)'
      : item.showLevel === 2
      ? 'linear-gradient(90deg, #E6C462 0%, #C67937 100%)'
      : 'linear-gradient(90deg, #6589E5 0%, #1F64B7 100%)';
  let bg2 = 'grey';

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '160px',
          px: '16px',
          py: '12px',
          borderRadius: '999px',
          background:
            activityRule.ruleState === 1
              ? bg2
              : item.userActivityState === 5 || item.userActivityState === 3
              ? 'background.paper'
              : bg1,
        },
        (item.userActivityState === 5 || item.userActivityState === 3) && {
          border: '1px solid #FFFFFF26',
        },
      ]}
      // onClick={() => {
      //   item.userActivityState === 1
      //     ? handleReceive(item)
      //     : handleBuy(item, activityRule?.ruleTargetAmt);
      // }}
      onClick={async () => {
        if (item.userActivityState === 5 || item.userActivityState === 3) {
          return;
        }
        if (loading) {
          return;
        }

        setLoading(true);
        await onClick(item);
        setLoading(false);
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{
            width: '18px !important',
            height: '18px !important',
            color: 'white',
          }}
        />
      ) : item.userActivityState === 5 ? (
        <Typography
          color={'text.secondary'}
          fontWeight={500}
          fontSize={16}
          fontStyle="italic"
        >
          {__('Discontinue')}
        </Typography>
      ) : item.userActivityState === 3 ? (
        <Typography
          color={'text.secondary'}
          fontWeight={500}
          fontSize={16}
          fontStyle="italic"
        >
          {__('Restricted')}
        </Typography>
      ) : item.userActivityState === 1 || item.userActivityState === 4 ? (
        <Typography
          color={
            activityRule.ruleState === 1 ? 'text.secondary' : 'common.white'
          }
          fontWeight={activityRule.ruleState === 1 ? 500 : 400}
          fontSize={16}
          fontStyle="italic"
        >
          {activityRule.ruleState === 1
            ? __('Already obtained')
            : __('Get rewarded')}
        </Typography>
      ) : (
        <Typography fontSize={16} fontStyle="italic" fontWeight={500}>
          {__('Only')}
          {` R$ ${activityRule?.ruleTargetAmt}`}
        </Typography>
      )}
    </Box>
  );
};

const CardBottomTime = ({ item }: { item: weekCardDto }) => {
  const { __ } = useLang();
  const [countDown, setCountDown] = useState<number>(item?.countdown || 0);
  useEffect(() => {
    if (countDown < 1) {
      return;
    }

    const interval = setInterval(() => {
      setCountDown(countDown - 1);
      if (countDown == 1) {
        interval && clearInterval(interval);
      }
    }, 1000);
    return () => {
      interval && clearInterval(interval);
    };
  }, [countDown]);

  const formatSeconds = (seconds: number) => {
    if (seconds < 1) {
      return '00:00:00';
    }
    var day = Math.floor(seconds / 86400);
    var hour = Math.floor((seconds % 86400) / 3600);
    var minute = Math.floor((seconds % 3600) / 60);
    var second = seconds % 60;
    let time =
      (hour > 9 ? hour : `0${hour}`) +
      ':' +
      (minute > 9 ? minute : `0${minute}`) +
      ':' +
      (second > 9 ? second : `0${second}`);
    if (day > 0) {
      return `${day}${__('day')} ${time}`;
    }
    return time;
  };

  return (
    <Box
      sx={{
        height: item?.acceptUserLimit && item?.countdown ? '60px' : '40px',
        bgcolor: 'background.neutral',
        // display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-8px',
        pt: '14px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      }}
    >
      {item?.acceptUserLimit ? (
        <Typography
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
          }}
          variant="body2"
          fontStyle="italic"
        >
          {`Quantidade restante `}
          <span
            style={{
              fontWeight: '700',
              color: '#fff',
              fontStyle: 'italic',
              fontSize: 14,
            }}
          >
            {item?.acceptUserLimit || ''}
          </span>
        </Typography>
      ) : null}
      {item?.countdown ? (
        <Typography
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
          }}
          variant="body2"
          fontStyle="italic"
        >
          {`Duração do evento `}
          <span
            style={{
              fontWeight: '700',
              color: '#fff',
              fontStyle: 'italic',
              fontSize: 14,
            }}
          >
            {formatSeconds(countDown)}
          </span>
        </Typography>
      ) : null}
    </Box>
  );
};

export const WeekCardList: React.FC<Props> = (props: Props) => {
  const { __, language } = useLang();

  const isMobile = useResponsive('down', 'md');
  const navigate = useNavigate();
  const [resOpen, setResOpen] = useBoolean();
  const [ruleOpen, setRuleOpen] = useBoolean();
  const [failOpen, setFailOpen] = useBoolean();
  const [payOpen, setPayOpen] = useBoolean();
  const [reciverData, setReciverData] = useState<any>(null);
  const [redeeData, setRedeeData] = useState<any[]>([]);
  const [weekFailData, setWeekFailData] = useState<any[]>([]);
  const [payData, setPayData] = useState<weekCardDto | null>(null);

  const [ruleTxt, setRuleTxt] = useState<String>('');
  const { getWallet, isLogin, openLogin } = useAuth();
  const [isOpenDepositUrl, setIsOpenDepositUrl] = useBoolean();
  const [depositUrl, setDepositUrl] = useState<string>('');
  const [buyLoading, setBuyLoading] = useState<boolean>(false);
  const [aweekCards, setAWeekCards] = useRecoilState(weekCardActivityState);
  const [openDeposit, setOpenDeposit] = useRecoilState(isOpenDepositState);
  const [showMoneyDialog, setShowMoneyDialog] =
    useRecoilState(showMoneyDialogState);
  let count = 0;
  const timer = useRef<NodeJS.Timeout>();

  const isEn = language === LanguageType.EN;
  const width = Math.min(window.innerWidth, globalMaxWidth) - 32;

  useEffect(() => {
    if (showMoneyDialog?.open) {
      setIsOpenDepositUrl.off();
      return;
    }
  }, [setIsOpenDepositUrl, showMoneyDialog]);

  const _onCardPress = async (card: any) => {
    if (!isLogin) {
      openLogin();
      return;
    }
    // setResOpen.on();
    // setRuleOpen.on();
    setOpenDeposit(false);
    setTimeout(() => {
      navigate(`/packageRecords/${card.id}`);
      // navigate(`/game/info?id=473`);
    }, 400);
  };
  const handleReceive = async (item: weekCardDto) => {
    try {
      let { data = [] } = await services.config.weekCardRecive(item.id);

      // setRedeeData(data);
      // setResOpen.on();
      closeDepositDialog();
      if (Array.isArray(data) && data?.length > 0) {
        setReciverData({
          userActivityId: item.userActivityId,
          activityId: item.id,
        });
        setRedeeData(data);
        setResOpen.on();
      } else {
        setWeekFailData(data?.ruleDetails || []);
        setFailOpen.on();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBuyLoading(false);
    }
  };
  const handleBuy = async (item: weekCardDto, money: number) => {
    if (!isLogin) {
      openLogin();
      return;
    }
    if (buyLoading) {
      return;
    }
    if (!props.deposit) {
      return;
    }
    if (money === 0) {
      const { min_money, max_money } = props.deposit!;
      if (+money > max_money || +money < min_money) {
        SnackbarUtils.error(
          __('moneyLimit')
            .replace('{min}', min_money)
            .replace('{max}', max_money),
        );
        return null;
      }
    }

    let params: any = {
      pay_id: props.deposit.id,
      money,
      activityId: item.id,
    };
    if (!params) {
      return;
    }
    setBuyLoading(true);
    try {
      const { data } = await services.wallet.deposit(params);
      const { type = '', info = '' } = data;
      if (type === 'url' && info) {
        setIsOpenDepositUrl.on();
        setDepositUrl(info);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBuyLoading(false);
    }
  };
  const handleBtn = async (item: any) => {
    let activityRule = item?.activityRulesList[0] || {};

    if (activityRule.ruleState === 0) {
      if (item.userActivityState === 1 || item.userActivityState === 4) {
        gaSend('充值模块', '周月卡奖励领取 - 点击', '充值页：Receber');

        await handleReceive(item);
      } else if (item.userActivityState === 2) {
        gaSend(
          '充值模块',
          '周月卡购买 - 点击',
          `充值页：Compre por  R$ ${activityRule?.ruleTargetAmt}`,
        );

        setPayData(item);
        setPayOpen.on();

        // await handleBuy(item, activityRule?.ruleTargetAmt);
      }
    }
  };

  const onPayCb = (url: string) => {
    setPayOpen.off();
    if (url && url.length > 0) {
      setIsOpenDepositUrl.on();
      setDepositUrl(url);
    } else {
      //
      setBuyLoading(true);
      count = 0;
      timer.current = setInterval(nowHeart, 1000);
    }
  };

  const nowHeart = () => {
    services.config.heartbeat().then(async (result) => {
      count++;
      if (!result) {
        return;
      }

      const { data } = result;
      const message = data?.message;

      if (message?.showType === 2 && message?.showLevel === 3) {
        setBuyLoading(false);
        let moneyMsg = { ...message, open: true };
        setShowMoneyDialog(moneyMsg);
        getWallet();
        const weekRes = await services.config.getActivityListV2(
          ActivityType.周卡,
        );
        setAWeekCards(weekRes || []);

        timer.current && clearInterval(timer.current);
      } else if (count === 5) {
        setBuyLoading(false);
        timer.current && clearInterval(timer.current);
        SnackbarUtils.success(__('W kolejce do odbioru'));
      }
    });
  };

  const closeDepositDialog = async () => {
    getWallet();
    setIsOpenDepositUrl.off();
    const res = await services.config.getActivityListV2(ActivityType.周卡);
    setAWeekCards(res || []);
  };

  if (props.loading || buyLoading) {
    return (
      <Box
        minHeight={'100vh'}
        px={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CommonLoading />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        px: 2,
        borderRadius: 1,
      }}
    >
      {props.list.map((item) => {
        let activityRule = item?.activityRulesList[0] || {};
        let bg =
          item.showLevel === 4
            ? card_limit
            : item.showLevel === 1
            ? card_7
            : item.showLevel === 2
            ? card_15
            : card_30;
        let bg1 =
          item.showLevel === 4
            ? card_limit_dis
            : item.showLevel === 1
            ? card_7_dis
            : item.showLevel === 2
            ? card_15_dis
            : card_30_dis;
        let box =
          item.showLevel === 4
            ? cardBox_limit
            : item.showLevel === 1
            ? cardBox_7
            : item.showLevel === 2
            ? cardBox_15
            : cardBox_30;

        let bottom =
          item.showLevel === 4
            ? card_Bottom_limit
            : item.showLevel === 1
            ? card_Bottom_7
            : item.showLevel === 2
            ? card_Bottom_15
            : card_Bottom_30;

        let isDisable =
          item?.userActivityState === 3 ||
          item?.userActivityState === 4 ||
          item?.userActivityState === 5; //是否停用
        let opacity = isDisable ? 0.7 : 1;
        return (
          <Box key={item.id}>
            <Stack
              sx={[
                {
                  mt: '24px',
                  backgroundImage: `url(${isDisable ? bg1 : bg})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  position: 'relative',
                  width: `${width}px`,
                  height: `${Math.min(width * 0.62, 250)}px`,
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  borderTopRightRadius: '8px',
                },
              ]}
            >
              {isDisable ? (
                <Box
                  sx={[
                    {
                      backgroundImage: `url(${cardBox_disable})`,
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      position: 'absolute',
                      display: 'flex',
                      zIndex: 10,
                      alignItems: 'center',
                    },
                    {
                      width: '48%',
                      height: '25%',
                      top: isDisable ? -2 : -15,
                      left: isDisable ? -2 : 17,
                    },
                  ]}
                >
                  <Typography
                    variant="subtitle2"
                    lineHeight={1}
                    fontWeight={500}
                    sx={{
                      color: 'text.secondary',
                      px: 2,
                      wordBreak: 'break-word',
                    }}
                    className="futura-font"
                  >
                    {__('Temporarily dismantle')}
                  </Typography>
                </Box>
              ) : (
                <img
                  src={isDisable ? cardBox_disable : box}
                  alt=""
                  style={{
                    width: isDisable ? 240 : 96,
                    height: isDisable ? 48 : 96,
                    top: isDisable ? -2 : -15,
                    left: isDisable ? -2 : 17,
                    position: 'absolute',
                  }}
                />
              )}

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  mt: '8px',
                  px: '12px',
                  opacity,
                }}
              >
                <Typography
                  sx={{
                    cursor: 'pointer',
                    fontSize: 20,
                    fontWeight: 700,
                    lineHeight: 1,
                  }}
                  fontWeight={700}
                  fontStyle="italic"
                  className="futura-font"
                >
                  {isEn ? item?.activityName?.enUS : item?.activityName?.ptBR}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mt: '8px',
                  px: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    mr: '24px',
                    alignItems: 'center',
                    opacity,
                  }}
                >
                  <img src={IC_QUESTION} alt="" width={12} height={12} />
                  <Typography
                    ml={'2px'}
                    variant="body2"
                    lineHeight={1}
                    color="text.secondary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setRuleTxt(
                        isEn
                          ? item?.activityBlurb?.enUS
                          : item?.activityBlurb?.ptBR,
                      );

                      gaSend(
                        '充值模块',
                        '周月卡规则 - 点击',
                        '充值页：Detalhes da regra',
                      );

                      setRuleOpen.on();
                    }}
                    fontStyle="italic"
                  >
                    {__(`Rule details`)}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ cursor: 'pointer', lineHeight: '16px' }}
                  fontWeight={500}
                  onClick={() => {
                    gaSend(
                      '充值模块',
                      '周月卡领取记录 - 点击',
                      '充值页：Registros',
                    );

                    _onCardPress(item);
                  }}
                  fontStyle="italic"
                >
                  {__('Records')}
                  {' >'}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '14px',
                  opacity,
                  flex: 1,
                }}
              >
                <Box sx={styles.title}>
                  <Box sx={styles.itemTxt}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ cursor: 'pointer', lineHeight: '12px' }}
                      textAlign="center"
                    >
                      {`Permanecer`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      mt: '4px',
                    }}
                  >
                    <Typography
                      color="common.white"
                      sx={{ cursor: 'pointer', fontSize: 20, fontWeight: 700 }}
                      className="futura-font"
                      fontStyle="italic"
                      lineHeight={'26px'}
                      fontWeight={700}
                    >
                      {`${item?.activityTripTimes}`}
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          lineHeight: '26px',
                          fontWeight: 400,
                        }}
                      >
                        {__('day')}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.title}>
                  <Box sx={styles.itemTxt}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ cursor: 'pointer', lineHeight: '12px' }}
                      textAlign="center"
                      fontStyle="italic"
                    >
                      {__(`First reception`)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      mt: '4px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ cursor: 'pointer', fontSize: 20, fontWeight: 700 }}
                      className="futura-font"
                    >
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          fontStyle: 'italic',
                          fontWeight: 400,
                        }}
                      >
                        R$
                      </span>
                      {` ${activityRule?.firstReturnAmt}`}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.title}>
                  <Box sx={styles.itemTxt}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ cursor: 'pointer', lineHeight: '12px' }}
                      textAlign="center"
                      fontStyle="italic"
                    >
                      {__(`Daily reception`)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      mt: '4px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="text.primary"
                      sx={{ cursor: 'pointer', fontSize: 20, fontWeight: 700 }}
                      className="futura-font"
                    >
                      <span
                        style={{
                          color: 'white',
                          fontSize: 12,
                          fontStyle: 'italic',
                          fontWeight: 400,
                        }}
                      >
                        R$
                      </span>
                      {` ${activityRule?.avgReturnAmt || ''}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={[
                  {
                    backgroundImage: `url(${bottom})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: '12px',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                    width: `${width}px`,
                    height: `${width * 0.2099}px`,
                  },
                  // isMobile
                  //   ? {
                  //       width: 'calc(100vw - 32px)',
                  //       height: 'calc((100vw - 32px)*0.2099)',
                  //     }
                  //   : {},
                ]}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    lineHeight={1}
                    sx={{ cursor: 'pointer', fontStyle: 'italic' }}
                  >
                    {__(`Total reception`)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      mt: '4px',
                    }}
                  >
                    <Typography
                      color="text.primary"
                      sx={{ cursor: 'pointer', fontSize: 24, fontWeight: 700 }}
                      lineHeight={'32px'}
                      fontWeight={700}
                      className="futura-font"
                    >
                      <span
                        style={{
                          color: 'white',
                          fontSize: 16,
                          fontStyle: 'italic',
                          fontWeight: 400,
                        }}
                      >
                        R$
                      </span>
                      {` ${activityRule?.rulePrizeAmt || ''}`}
                    </Typography>
                  </Box>
                </Box>
                <CardBottomBtn item={item} onClick={handleBtn} />
              </Box>
            </Stack>
            {item?.countdown || item?.acceptUserLimit ? (
              <CardBottomTime item={item} />
            ) : null}
          </Box>
        );
      })}
      <Dialog
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: globalMaxWidth,
          },
        }}
        open={isOpenDepositUrl}
        onClose={() => {
          closeDepositDialog();
        }}
      >
        <Box>
          <iframe
            title="deposit"
            src={depositUrl}
            frameBorder={0}
            id="gameIframe"
            style={{
              width: '100%',
              height: '600px',
              verticalAlign: 'middle',
            }}
          ></iframe>
        </Box>
      </Dialog>
      <PackageResDialog
        resData={redeeData}
        showObject={reciverData}
        open={resOpen}
        onClose={setResOpen.off}
      />
      <CardRuleDrawer
        open={ruleOpen}
        onClose={setRuleOpen.off}
        ruleTxt={ruleTxt}
      />

      <WeekCardFailDrawer
        open={failOpen}
        onClose={setFailOpen.off}
        list={weekFailData || []}
      />
      <WeekCardDepositDrawer
        open={payOpen}
        onClose={setPayOpen.off}
        onPayCb={onPayCb}
        data={payData}
      />
    </Box>
  );
};

const styles = {
  title: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  itemTxt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    px: '2px',
  },
  valueTxt: {
    cursor: 'pointer',
    fontSize: 20,
    mt: '4px',
  },
};
