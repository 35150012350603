import ICON_RANK_IN from '@/assets/icons/rank-in.png';
import ICON_RANK_OUT from '@/assets/icons/rank-out.png';
import Center from '@/components/center';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import { gaSend } from '@/utils/ga';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useRef } from 'react';

export const MyRank = (props: {
  rank?: number;
  value?: number;
  type: number;
  refresh: () => Promise<void>;
  loading: boolean;
}) => {
  const isRankIn = !!props.rank;
  const timeoutRef = useRef<number>();
  const isLimit = useRef(false);

  return (
    <Box
      sx={{
        mt: 2,
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        background: isRankIn
          ? 'linear-gradient(0deg, rgba(255, 230, 0, 0.75) 0%, rgba(255, 230, 0, 0.19) 100%)'
          : 'linear-gradient(0deg, rgba(241, 75, 115, 0.75) 0%, rgba(241, 75, 115, 0.19) 100%)',
      }}
    >
      <Box
        sx={{
          borderRadius: '6px',
          overflow: 'hidden',
          px: 2,
          py: 1,
          background: isRankIn
            ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.06) 100%)'
            : 'linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.06) 100%)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            alt=""
            src={isRankIn ? ICON_RANK_IN : ICON_RANK_OUT}
            width={24}
            height={24}
            style={{ minWidth: 24, marginRight: 8 }}
          />
          <Typography sx={{ fontSize: 14 }} fontWeight={500}>
            {isRankIn
              ? 'Parabéns, você entrou na lista!'
              : 'Infelizmente você não entrou na lista.'}
          </Typography>
        </Box>
      </Box>
      {/* <Box
        sx={{
          p: 1,
          px: 1.5,
          borderRadius: '6px',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: isRankIn
            ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.06) 100%)'
            : 'linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.06) 100%)',
        }}
      >
        <Typography variant="body2" fontWeight={500} mr={1} color="#3D333C">
          {isRankIn
            ? 'Sua classificação atual:'
            : props.type === 3
            ? 'O valor da sua aposta:'
            : 'Recarga da sua equipe:'}
        </Typography>
        {isRankIn ? (
          <Typography
            fontSize={24}
            fontWeight={700}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {isRankIn ? props.rank : `R$ ${props.value?.toFixed(0) ?? 0}`}
          </Typography>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" fontWeight={500} mr={'2px'}>
              R$
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={700}
              sx={{
                textTransform: 'capitalize',
              }}
            >
              {props.value?.toFixed(0) ?? 0}
            </Typography>
          </Box>
        )}
      </Box> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text.secondary',
          mt: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          Total de apostas:
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 500,
            ml: 1,
          }}
        >
          R$
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            ml: '2px',
          }}
        >
          {props.value?.toFixed(0) ?? 0}
        </Typography>
        <Center
          sx={{
            width: 24,
            height: 24,
            ml: 1,
          }}
          onClick={() => {
            gaSend('首页模块', '排行榜刷新 - 点击', '首页：排行榜【刷新】icon');

            if (isLimit.current) {
              SnackbarUtils.info(
                'A operação é muito frequente. Tente novamente mais tarde.',
              );
              return;
            }

            if (timeoutRef.current) {
              window.clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = window.setTimeout(() => {
              isLimit.current = false;
            }, 1000 * 10);

            isLimit.current = true;
            props.refresh();
          }}
        >
          {props.loading ? (
            <CircularProgress
              sx={{ color: 'text.secondary' }}
              size={18}
              thickness={6}
            />
          ) : (
            <RefreshRoundedIcon sx={{ fontSize: 24 }} />
          )}
        </Center>
      </Box>
    </Box>
  );
};
