import Center from '@/components/center';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import { supportListState } from '@/stores/config';
import { LiveChatWidget } from '@livechat/widget-react';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilValue } from 'recoil';

export const LivechatPage = () => {
  const supportList = useRecoilValue(supportListState);
  const liveSupport = supportList.find((su) => +su.type === 5);
  const license = liveSupport?.code;
  const { isLogin, user } = useAuth();
  const [isReady, setIsReady] = useBoolean();

  if (!isLogin || !liveSupport || !license) {
    return (
      <PageContainer>
        <Center height="100%">
          <CircularProgress />
        </Center>
      </PageContainer>
    );
  }

  console.log('render LivechatPage');

  return (
    <PageContainer>
      <Center height="100%">{!isReady && <CircularProgress />}</Center>
      <LiveChatWidget
        license={license}
        visibility="maximized"
        chatBetweenGroups={false}
        customerName={`Player: ${user?.uid}`}
        customerEmail={user?.email}
        onReady={() => {
          console.log('LiveChatWidget onReady');
          setIsReady.on();
        }}
        onVisibilityChanged={(e) => {
          console.log(e);

          if (e.visibility === 'hidden') {
            if (window.opener) {
              window.close();
            }
          }
        }}
      />
    </PageContainer>
  );
};

const PageContainer = (props: { children: React.ReactNode }) => {
  // const width = Math.min(window.innerWidth, globalMaxWidth);
  return (
    <Box
      sx={{
        // width: `${width}px`,
        width: '100%',
        mx: 'auto',
        height: '100vh',
        bgcolor: 'background.default',
      }}
    >
      {props.children}
    </Box>
  );
};
