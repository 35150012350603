import { globalMaxWidth } from '@/theme';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from '@mui/material';
import * as React from 'react';
import useResponsive from '../../hooks/useResponsive';
import Center from '../center';

interface Props extends SwipeableDrawerProps {
  children: React.ReactNode;
  title: string;
  onBack?: () => void;
  hideCloseBtn?: boolean;
}

/**
 * 通用侧边栏组件
 * 手机端从底部弹出
 * 桌面端从右侧弹出
 */
const CommonDrawerV2: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');

  const { children, title, onBack, hideCloseBtn, ...drawerProps } = props;

  return (
    <SwipeableDrawer
      anchor={isMobile ? 'bottom' : 'right'}
      {...drawerProps}
      ModalProps={{
        container: document.getElementById('root') as HTMLElement,
      }}
      disableSwipeToOpen
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      PaperProps={{
        sx: isMobile
          ? {
              borderRadius: '16px 16px 0 0',
              width: '100%',
              maxWidth: globalMaxWidth,
              left: {
                xs: 0,
                sm: `calc(50% - ${globalMaxWidth / 2}px)`,
              },
            }
          : null,
      }}
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: Math.min(window.innerWidth, globalMaxWidth),
          overflowY: 'hidden',
          // minHeight: "100vh",
          // maxHeight: "100vh",
          // paddingTop: isMobile ? "96px" : 0,
        }}
      >
        <Center sx={{ height: '56px' }}>
          <Typography variant="h5">{props.title}</Typography>
        </Center>
        {onBack ? (
          <Center
            position="absolute"
            left={8}
            top={isMobile ? 8 : 20}
            width={40}
            height={40}
            onClick={onBack}
            zIndex={10}
          >
            <ArrowBackIosNewRoundedIcon sx={{ fontSize: 24 }} />
          </Center>
        ) : null}
        {hideCloseBtn ? null : (
          <Center
            position="absolute"
            right={8}
            top={isMobile ? 8 : 20}
            width={40}
            height={40}
            onClick={props.onClose}
            zIndex={10}
          >
            <CloseIcon sx={{ fontSize: 28 }} />
          </Center>
        )}
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default CommonDrawerV2;
