import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenSignSuccessState, signListState } from '@/stores/auth';
import { globalMaxWidth } from '@/theme';
import { Box, Dialog, SwipeableDrawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import DrawerHeder from './DrawerHeder';
import SignSuccessConten from './SignSuccessConten';

interface Props {
  lastSign?: number;
}

const SignSuccessDrawer = (props: Props) => {
  const { __ } = useLang();
  const [signList] = useRecoilState(signListState);
  const [openSignSuccess, setOpenSignSuccess] = useRecoilState(
    isOpenSignSuccessState,
  );

  const [dayList, setDayList] = useState<Array<any>>([]);
  const isMobile = useResponsive('down', 'md');

  useEffect(() => {
    if (openSignSuccess) {
      let dayListTmp: {}[][] = [];
      const chunkSize = 7;
      let result = [];
      let index = 0;
      // 分割长度7子数组
      while (index < signList.length) {
        result.push(signList.slice(index, index + chunkSize));
        index += chunkSize;
      }

      result.map((day7, index) => {
        let tmp = index % 2;
        if (tmp == 0) {
          dayListTmp.push(day7);
        } else {
          dayListTmp.push([...day7].reverse());
        }
      });
      setDayList(dayListTmp);
    }
  }, [openSignSuccess, signList]);

  const _onClose = () => {
    setOpenSignSuccess(false);
  };

  const _onOpen = () => {
    setOpenSignSuccess(true);
  };

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={openSignSuccess}
        // open={true}
        disableSwipeToOpen
        onClose={_onClose}
        onOpen={_onOpen}
        anchor={isMobile ? 'bottom' : 'right'}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: globalMaxWidth,
            left: {
              xs: 0,
              sm: `calc(50% - ${globalMaxWidth / 2}px)`,
            },
          },
        }}
      >
        <Box
          style={{
            overflowY: 'hidden',
          }}
          pb={2}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder title={__('Sign In Successfully')} onClose={_onClose} />
          <SignSuccessConten dayList={dayList} />
        </Box>
      </SwipeableDrawer>
    );
  }
  return (
    <Dialog
      open={openSignSuccess}
      // open={true}
      PaperProps={{
        sx: {
          width: '400px',
          height: '586px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
        },
      }}
      onClose={_onClose}
      aria-labelledby="sign-in"
    >
      <Box
        style={{
          overflowY: 'hidden',
        }}
        pb={2}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder title={__('Sign In Successfully')} onClose={_onClose} />
        <SignSuccessConten dayList={dayList} />
      </Box>
    </Dialog>
  );
};

export default SignSuccessDrawer;
