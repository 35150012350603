import ICON_REFRESH from '@/assets/icons/ic_refresh.png';
import { useRefreshActivity } from '@/hooks/useActivity';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import {
  isOpenTransferState,
  isOpenWithdrawerState,
  withdrawerTypeState,
} from '@/stores/auth';
import { ActivityType } from '@/types';
import { gaSend } from '@/utils/ga';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import TransferDrawer from '../Tabs/TransferDrawer';

export const AgentWalletBox = () => {
  const { user, getUserInfo, isLogin, openLogin } = useAuth();
  const { __ } = useLang();
  const setOpenWithdraw = useSetRecoilState(isOpenWithdrawerState);
  const setWithdrawType = useSetRecoilState(withdrawerTypeState);
  const setOpenTransfer = useSetRecoilState(isOpenTransferState);
  const { refresh, loading } = useRefreshActivity(ActivityType.邀新活动);

  const handleClickWithdraw = async () => {
    gaSend('邀请模块', '邀请奖金提现 - 点击', '邀请页：Retirar');

    if (!isLogin) {
      openLogin();
      return;
    }

    if (user?.cpfState === 1) {
      await getUserInfo();
    }
    setWithdrawType(2);
    setOpenWithdraw(true);
  };

  return (
    <Box px={{ xs: 1.5 }} width="100%">
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          p: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight={500}
          fontStyle="italic"
          lineHeight={1}
        >
          {__('Rewards&Commissions')}
        </Typography>
        <Box sx={{ py: 1.5 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle2" fontStyle="italic">
                R$
              </Typography>
              <Typography variant="h1" fontSize={28} lineHeight={1.25}>
                {user?.agent_money?.toFixed(2) || 0}
              </Typography>
            </Stack>

            <Box
              sx={{
                width: 24,
                height: 24,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                if (!isLogin) {
                  openLogin();
                  return;
                }
                refresh();
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="info" />
              ) : (
                <img
                  style={{ width: 24, height: 24 }}
                  src={ICON_REFRESH}
                  alt=""
                />
              )}
            </Box>
          </Stack>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={handleClickWithdraw}
            sx={{
              bgcolor: '#313D4D',
              borderRadius: 1,
              flex: 1,
              px: 2,
              height: 40,
              '&:hover': {
                bgcolor: '#313D4D',
              },
              boxShadow: 'none !important',
              fontStyle: 'italic',
            }}
          >
            {__('提取')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              gaSend('邀请模块', '邀请奖金转账 - 点击', '邀请页：Transferir');

              if (!isLogin) {
                openLogin();
                return;
              }
              setOpenTransfer(true);
            }}
            sx={{
              // bgcolor: ColorfulBg(),
              borderRadius: 1,
              flex: 1,
              px: 2,
              height: 40,
              '&:hover': {
                bgcolor: '#36A8F4',
              },
              boxShadow: 'none !important',
              fontStyle: 'italic',
            }}
          >
            {__('Transfer')}
          </Button>
        </Stack>
      </Box>

      <TransferDrawer />
    </Box>
  );
};
