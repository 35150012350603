import useLang from '@/hooks/useLang';
import {
  depositTabState,
  isLoginState,
  isOpenDepositState,
  isOpenLRModalState,
  LRModalInitalPage,
} from '@/stores/auth';
import {
  appNameState,
  homeTab as homeTabState,
  inviteOpenAndWinTabState,
  isShowHomeGuide,
  promotionListState,
  tenantConfigState,
} from '@/stores/config';
import { HomeTab } from '@/types';
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import {
//   default as IMAGE_CASINO,
//   default as IMAGE_CASINO_ACTIVE,
// } from '../../assets/nav/icon_casinoOn.svg';
// import IMAGE_INVITE from '../../assets/nav/icon_inviteOff.svg';
// import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_inviteOn.svg';
// import IMAGE_ME from '../../assets/nav/icon_meOff.svg';
// import IMAGE_ME_ACTIVE from '../../assets/nav/icon_meOn.svg';
// import IMAGE_LUCKY from '../../assets/nav/icon_spinnerOff.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_spinnerOn.svg';
// import IMAGE_VIP from '../../assets/nav/icon_vipOff.svg';
// import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_vipOn.svg';
// 副站-1
// import {
//   default as IMAGE_CASINO,
//   default as IMAGE_CASINO_ACTIVE,
// } from '../../assets/nav/icon_Casino_1.svg';
// import IMAGE_INVITE from '../../assets/nav/icon_Invite_off_2.svg';
// import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_Invite_on_1.svg';
// import IMAGE_ME from '../../assets/nav/icon_Person_off_2.svg';
// import IMAGE_ME_ACTIVE from '../../assets/nav/icon_Person_on_1.svg';
// import IMAGE_LUCKY from '../../assets/nav/icon_Spinner_off_2.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_Spinner_on_1.svg';
// import IMAGE_VIP from '../../assets/nav/icon_VIP_off_2.svg';
// import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_VIP_on_1.svg';
// 副站-2
// import {
//   default as IMAGE_CASINO,
//   default as IMAGE_CASINO_ACTIVE,
// } from '../../assets/nav/icon_Casino_2.svg';
// import IMAGE_INVITE from '../../assets/nav/icon_Invite_off_2.svg';
// import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_Invite_on_2.svg';
// import IMAGE_ME from '../../assets/nav/icon_Person_off_2.svg';
// import IMAGE_ME_ACTIVE from '../../assets/nav/icon_Person_on_2.svg';
// import IMAGE_LUCKY from '../../assets/nav/icon_Spinner_off_2.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_Spinner_on_2.svg';
// import IMAGE_VIP from '../../assets/nav/icon_VIP_off_2.svg';
// import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_VIP_on_2.svg';
// 副站-4
import IMAGE_DEPOSIT from '@/assets/nav/icon_Deposit_off.png';
import { Skeleton } from '@mui/material';
import {
  default as IMAGE_CASINO,
  default as IMAGE_CASINO_ACTIVE,
} from '../../assets/nav/icon_Casino_6.svg';
import IMAGE_INVITE from '../../assets/nav/icon_Invite_off_2.svg';
import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_Invite_on_5.svg';
import IMAGE_ME from '../../assets/nav/icon_Person_off_2.svg';
import IMAGE_ME_ACTIVE from '../../assets/nav/icon_Person_on_5.svg';
// 1.2暂时注释抽奖
// import IMAGE_LUCKY from '../../assets/nav/icon_Spinner_off_2.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_Spinner_on_5.svg';
import Center from '@/components/center';
import { footerbarHeight, globalMaxWidth } from '@/theme';
import { gaSend } from '@/utils/ga';
import { useMemo } from 'react';
import IMAGE_VIP from '../../assets/nav/icon_VIP_off_2.svg';
import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_VIP_on_5.svg';
import { AppBarBg } from './app-bar-bg';
import './index.css';

interface Props {}

const MoblieAppbar: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = useRecoilValue(isLoginState);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setIsShowDLGuide = useSetRecoilState(isShowHomeGuide);
  const openDiposit = useSetRecoilState(isOpenDepositState);
  const setDepositTab = useSetRecoilState(depositTabState);
  const appName = useRecoilValue(appNameState);

  const [homeTab, setHomeTab] = useRecoilState(homeTabState);
  const setInviteOpenAndWinTab = useSetRecoilState(inviteOpenAndWinTabState);
  const tenantConfig = useRecoilValue(tenantConfigState);
  const promotionList = useRecoilValue(promotionListState);

  const hasUnReceive = useMemo(() => {
    return promotionList.some((item) => Number(item?.unReceiveTimes ?? 0) > 0);
  }, [promotionList]);

  const { __ } = useLang();
  const handleClickWithLAuthCheck = (path: string) => {
    if (!isLogin) {
      // navigate('/signin');
      setInitalPage(0);
      setOpenLRModal(true);
    } else {
      // navigate(path);
      if (path === '/lucky') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Spinner);
      } else if (path === '/commissions') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Invite);
      } else if (path === '/vip') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Vip);
      } else if (path === '/me') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Me);
      } else if (path === '/promotions') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Bonus);
      } else {
        setHomeTab(HomeTab.Home);
      }

      if (location.pathname !== '/') {
        navigate('/');
      }
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        maxWidth: globalMaxWidth,
        width: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        // bgcolor: 'background.neutral',
      }}
    >
      <Toolbar
        sx={{
          minHeight: `${footerbarHeight}px !important`,
          px: '0px',
        }}
      >
        <Box
          sx={{
            maxWidth: globalMaxWidth,
            width: '100%',
            mx: 'auto',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height={footerbarHeight}
          >
            <Center flex={1}>
              <Box
                position="relative"
                width="100%"
                maxWidth="75px"
                height={footerbarHeight}
                onClick={() => {
                  // handleClickWithLAuthCheck('/promotions')
                  gaSend('导航模块', '活动 - 点击', '全局页：Promoções');

                  setHomeTab(HomeTab.Bonus);
                }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  spacing="4px"
                  height={footerbarHeight}
                >
                  {tenantConfig?.iconConfig?.bottomNavIcon?.bonusOn ? (
                    <img
                      className="no-select"
                      src={
                        homeTab === HomeTab.Bonus
                          ? tenantConfig?.iconConfig?.bottomNavIcon?.bonusOn ||
                            IMAGE_VIP_ACTIVE
                          : tenantConfig?.iconConfig?.bottomNavIcon?.bonusOff ||
                            IMAGE_VIP
                      }
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Skeleton variant="circular" width={24} height={24} />
                  )}

                  <Typography
                    variant="body2"
                    lineHeight={1}
                    color={
                      homeTab === HomeTab.Bonus
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                  >
                    {__('Bonus')}
                  </Typography>
                </Stack>

                {homeTab === HomeTab.Bonus && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: -1,
                    }}
                  >
                    <AppBarBg />
                  </Box>
                )}

                {hasUnReceive && (
                  <Box
                    sx={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '4px',
                      left: 'calc(50% + 10px)',
                      bgcolor: 'error.main',
                    }}
                  ></Box>
                )}
              </Box>
            </Center>
            <Center flex={1} height={footerbarHeight}>
              <Box
                width="100%"
                maxWidth="75px"
                height={footerbarHeight}
                onClick={() => {
                  gaSend('导航模块', '充值 - 点击', '全局页：Depósito');

                  setDepositTab('amount');
                  openDiposit(true);
                }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  spacing="4px"
                  height={footerbarHeight}
                >
                  {tenantConfig?.iconConfig?.bottomNavIcon?.inviteOn ? (
                    <img
                      className="no-select"
                      src={IMAGE_DEPOSIT}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Skeleton variant="circular" width={24} height={24} />
                  )}

                  <Typography
                    variant="body2"
                    lineHeight={1}
                    color="text.secondary"
                  >
                    {__('Deposit')}
                  </Typography>
                </Stack>
              </Box>
            </Center>

            <Center flex={1}>
              <Box
                position="relative"
                width="100%"
                maxWidth="75px"
                height={footerbarHeight}
                onClick={() => {
                  gaSend('导航模块', '大厅 - 点击', `全局页：${appName}`);

                  setHomeTab(HomeTab.Home);
                }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  spacing="4px"
                  height={footerbarHeight}
                >
                  <Box width={24} height={24}></Box>
                  <Typography
                    variant="body2"
                    lineHeight={1}
                    color={
                      homeTab === HomeTab.Home
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                  >
                    {appName}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-16px',
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {tenantConfig?.iconConfig?.bottomNavIcon?.casionOn ? (
                    <Box
                      sx={{
                        width: '44px',
                        height: '44px',
                        p: '2px',
                        bgcolor: 'background.default',
                        borderRadius: '50%',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        className="no-select"
                        src={
                          // location.pathname === '/'
                          homeTab === HomeTab.Home
                            ? tenantConfig?.iconConfig?.bottomNavIcon
                                ?.casionOn || IMAGE_CASINO_ACTIVE
                            : tenantConfig?.iconConfig?.bottomNavIcon
                                ?.casionOff || IMAGE_CASINO
                        }
                        alt=""
                        style={{ width: 40, height: 40 }}
                      />
                    </Box>
                  ) : (
                    <Skeleton variant="circular" width={40} height={40} />
                  )}
                </Box>

                {homeTab === HomeTab.Home && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: -1,
                    }}
                  >
                    <AppBarBg />
                  </Box>
                )}
              </Box>
            </Center>

            <Center flex={1}>
              <Box
                position="relative"
                width="100%"
                maxWidth="75px"
                height={footerbarHeight}
                onClick={() => {
                  gaSend('导航模块', '邀请 - 点击', '全局页：Convite');

                  setInviteOpenAndWinTab('Open');
                  setHomeTab(HomeTab.Invite);
                }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  spacing="4px"
                  height={footerbarHeight}
                >
                  {tenantConfig?.iconConfig?.bottomNavIcon?.inviteOn ? (
                    <img
                      className="no-select"
                      src={
                        // location.pathname === '/commissions'
                        homeTab === HomeTab.Invite
                          ? tenantConfig?.iconConfig?.bottomNavIcon?.inviteOn ||
                            IMAGE_INVITE_ACTIVE
                          : tenantConfig?.iconConfig?.bottomNavIcon
                              ?.inviteOff || IMAGE_INVITE
                      }
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Skeleton variant="circular" width={24} height={24} />
                  )}

                  <Typography
                    variant="body2"
                    lineHeight={1}
                    color={
                      homeTab === HomeTab.Invite
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                  >
                    {__('Invite')}
                  </Typography>
                </Stack>

                {homeTab === HomeTab.Invite && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: -1,
                    }}
                  >
                    <AppBarBg />
                  </Box>
                )}
              </Box>
            </Center>

            <Center flex={1}>
              <Box
                position="relative"
                width="100%"
                maxWidth="75px"
                height={footerbarHeight}
                onClick={() => {
                  gaSend('导航模块', '我的 - 点击', '全局页：EU');

                  handleClickWithLAuthCheck('/me');
                }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  spacing="4px"
                  height={footerbarHeight}
                >
                  {tenantConfig?.iconConfig?.bottomNavIcon?.personOn ? (
                    <img
                      className="no-select"
                      src={
                        // location.pathname === '/me'
                        homeTab === HomeTab.Me
                          ? tenantConfig?.iconConfig?.bottomNavIcon?.personOn ||
                            IMAGE_ME_ACTIVE
                          : tenantConfig?.iconConfig?.bottomNavIcon
                              ?.personOff || IMAGE_ME
                      }
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Skeleton variant="circular" width={24} height={24} />
                  )}

                  <Typography
                    variant="body2"
                    lineHeight={1}
                    color={
                      homeTab === HomeTab.Me ? 'text.primary' : 'text.secondary'
                    }
                  >
                    {__('ME')}
                  </Typography>
                </Stack>

                {homeTab === HomeTab.Me && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: -1,
                    }}
                  >
                    <AppBarBg />
                  </Box>
                )}
              </Box>
            </Center>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MoblieAppbar;
