import ICON_FACEBOOK from '@/assets/footer/icon_facebook@2x.png';
import ICON_GOOGLE from '@/assets/footer/icon_google@2x.png';
import ICON_INSTAGRAM from '@/assets/footer/icon_instagram@2x.png';
import ICON_TELEGRAM from '@/assets/footer/icon_telegram@2x.png';
import ICON_TIKTOK from '@/assets/footer/icon_tiktok@2x.png';
import ICON_TWITTER from '@/assets/footer/icon_twitter@2x.png';
import ICON_YOUTUBE from '@/assets/footer/icon_YouTube@2x.png';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { bottomSupportList, prominentSupportList } from '@/stores/config';
import { gaSend } from '@/utils/ga';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

interface Props {
  page?: string;
}
const styles = {
  mobieImg: { width: 28, height: 28 },
};
const FooterLinks: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');
  const linkList = useRecoilValue(bottomSupportList);

  if (isMobile) {
    return (
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          mb: 1.5,
          textAlign: 'left',
        }}
      >
        <FooterSupportsBar
          bgcolor="background.neutral"
          module={props.page === 'detail' ? '详情页模块' : '首页模块'}
          page={props.page === 'detail' ? '详情页' : '首页'}
        />
        <Box
          sx={{
            py: '2px',
          }}
        >
          <Stack
            justifyContent="center"
            width="100%"
            direction="row"
            spacing={0}
            flexWrap="wrap"
          >
            {linkList.map((item, index) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    width: '48px',
                    height: '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton href={item.url} target="_blank">
                    <img
                      src={item.qr}
                      alt="Sereia Bet"
                      style={styles.mobieImg}
                      className="no-select"
                    />
                  </IconButton>
                </Box>
              );
            })}
          </Stack>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      p={1}
      sx={{
        bgcolor: {
          xs: 'card.main',
        },
        borderRadius: '8px',
      }}
    >
      <Stack
        justifyContent="center"
        width="100%"
        direction="row"
        spacing={5}
        flexWrap="wrap"
      >
        <IconButton href="https://t.me/SereiabetOficial" target="_blank">
          <img
            src={ICON_TELEGRAM}
            alt="Sereia Bet"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://www.facebook.com/profile.php?id=61551877903551&mibextid=ZbWKwL"
          target="_blank"
        >
          <img
            src={ICON_FACEBOOK}
            alt="facebook"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://instagram.com/sereiabetofc?igshid=MzRlODBiNWFlZA=="
          target="_blank"
        >
          <img
            src={ICON_INSTAGRAM}
            alt="instagram"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton href="https://biolink.info/sereiabetofc" target="_blank">
          <img
            src={ICON_GOOGLE}
            alt="google"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://www.youtube.com/@sereiabetofc"
          target="_blank"
        >
          <img
            src={ICON_YOUTUBE}
            alt="sereia"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton
          href="https://www.tiktok.com/@sereiabetofc?_t=8gKTqB69pvp&_r=1"
          target="_blank"
        >
          <img
            src={ICON_TIKTOK}
            alt="tiktok"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        <IconButton href="https://twitter.com/sereiabetofc" target="_blank">
          <img
            src={ICON_TWITTER}
            alt="twitter"
            style={{ width: 40, height: 40 }}
          />
        </IconButton>
        {/* <IconButton href="https://wa.me/5564981291313" target="_blank">
          <img
            src={ICON_SEREIA}
            alt="sereia"
            style={{ width: 40, height: 40 }}
          />
        </IconButton> */}
      </Stack>
    </Box>
  );
};

export default FooterLinks;

export const FooterSupportsBar = (props: {
  bgcolor?: string;
  module: string;
  page: string;
}) => {
  const PromList = useRecoilValue(prominentSupportList);
  const { __ } = useLang();
  return (
    <Box
      sx={{
        borderRadius: 1,
        bgcolor: props.bgcolor ?? 'background.paper',
        px: 2,
        py: 1.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontStyle: 'italic',
        }}
      >
        <Box sx={{ flex: 'auto', minWidth: 0 }}>
          <Typography variant="body1" fontWeight={700}>
            {__('Comunidade oficial recomendada')}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={0.5}>
            {__('As informações mais recentes e rápidas')}
          </Typography>
        </Box>
        <Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            {PromList.map((item) => {
              return (
                <IconButton
                  key={item.id}
                  sx={{ p: 0 }}
                  href={item.url}
                  target="_blank"
                  onClick={() => {
                    gaSend(
                      props.module,
                      `加入官方渠道${item.name} -点击`,
                      `${props.page}：Participe dos canais oficiais 【${item.name}】icon`,
                    );
                  }}
                >
                  <img src={item.qr} alt="" width={32} height={32} />
                </IconButton>
              );
            })}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
