import FLAG_BR from '@/assets/icons/icon-br.png';
import FLAG_US from '@/assets/icons/icon-us.png';
import ICON_BR from '@/assets/icons/icon_br.png';
import ICON_DOWN from '@/assets/icons/icon_down.png';
import ICON_EN from '@/assets/icons/icon_english.png';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { drawerWidth } from '@/theme';
import { LanguageType } from '@/types';
import { gaSend } from '@/utils/ga';
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useState } from 'react';

const LanguageRow = ({
  src,
  lang,
  show = false,
}: {
  src: string;
  lang: string;
  show?: boolean;
}) => {
  const isMobile = useResponsive('down', 'md');
  return (
    <Stack direction="row" spacing="3px" alignItems="center">
      <img
        style={{
          width: isMobile ? 16 : 22,
          height: isMobile ? 16 : 22,

          verticalAlign: 'middle',
        }}
        src={src}
        alt=""
      />
      <Typography variant="body1">{lang}</Typography>
      {show ? (
        <img src={ICON_DOWN} alt="" style={{ width: 8, height: 8 }} />
      ) : null}
    </Stack>
  );
};

const LocaleButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { language, modifyLanguage } = useLang();
  const isMobile = useResponsive('down', 'md');

  const handleClickEn = () => {
    setAnchorEl(null);
    modifyLanguage(LanguageType.EN);
  };

  const handleClickPt = () => {
    setAnchorEl(null);
    modifyLanguage(LanguageType.PT);
  };

  return (
    <Box px={2}>
      <Button
        sx={{
          px: 2,
          py: { xs: 1 },
          borderRadius: 1,
          borderColor: '#3C3952',
          width: isMobile ? 140 : drawerWidth - 32,
        }}
        color="info"
        variant="outlined"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <LanguageRow
          src={language === LanguageType.EN ? ICON_EN : ICON_BR}
          lang={language === LanguageType.EN ? 'English' : 'Português'}
          show={true}
        />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            bgcolor: 'card.main',
            width: isMobile ? 124 : 248,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleClickEn}>
          <LanguageRow src={ICON_EN} lang="English" />
        </MenuItem>
        <MenuItem onClick={handleClickPt}>
          <LanguageRow src={ICON_BR} lang="Português" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export const LocaleButtonMobile = () => {
  const { language, modifyLanguage } = useLang();

  const handleClickEn = () => {
    gaSend('首页模块', '英语语言切换 - 点击', `首页：底部【English】`);
    modifyLanguage(LanguageType.EN);
  };

  const handleClickPt = () => {
    gaSend('首页模块', '巴西语言切换 - 点击', `首页：底部【Português】`);
    modifyLanguage(LanguageType.PT);
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.neutral',
        p: 0.5,
        borderRadius: 1,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '88px',
          height: '28px',
          bgcolor:
            language === LanguageType.PT ? 'primary.main' : 'transparent',
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={handleClickPt}
      >
        <img src={FLAG_BR} alt="" style={{ width: 16, height: 12 }} />
        <Typography sx={{ fontSize: '10px', lineHeight: '12px', ml: '4px' }}>
          Português
        </Typography>
      </Box>
      <Box
        sx={{
          width: '88px',
          height: '28px',
          bgcolor:
            language === LanguageType.EN ? 'primary.main' : 'transparent',
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={handleClickEn}
      >
        <img src={FLAG_US} alt="" style={{ width: 16, height: 12 }} />
        <Typography sx={{ fontSize: '10px', lineHeight: '12px', ml: '4px' }}>
          English
        </Typography>
      </Box>
    </Box>
  );
};

export default LocaleButton;
